import axios from 'axios';

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 基础 URL 从环境变量中获取
    timeout: 10000, // 请求超时设置
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 可以在这里添加请求的配置，例如添加 Token
        return config;
    },
    error => {
        console.error('请求错误:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 处理响应数据
        return response.data; // 返回响应体
    },
    error => {
        console.error('响应错误:', error);
        return Promise.reject(error); // 抛出错误
    }
);

// 封装 POST 请求
export const post = async (url, data) => {
    try {
        const response = await service.post(url, data);
        return response;
    } catch (error) {
        console.error('POST 请求失败:', error);
        throw error; // 抛出错误以便调用者处理
    }
};

// 其他 HTTP 方法（GET, PUT, DELETE）可按需添加
export const get = async (url, params) => {
    try {
        const response = await service.get(url, { params });
        return response;
    } catch (error) {
        console.error('GET 请求失败:', error);
        throw error;
    }
};

// 可以继续添加 PUT, DELETE 等请求方法
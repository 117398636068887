<template>
  <el-container>
          <el-row :gutter="20" class="header">
            <el-col :span="4"></el-col>
            <el-col :span="16">

              <div class="header-title">
                <div class="header-image">
                  <a href="#"><img src="../assets/sitemap_logo_black_v3.png" /></a>
                </div>
                <div class="header-title-item header-title-item-active">免费生成</div>
<!--                <div class="header-title-item">我的站点</div>-->
<!--                <div class="header-title-item">在线服务</div>-->
<!--                <div class="header-title-item">功能介绍</div>-->
<!--                <div class="header-title-item">帮助中心</div>-->
              </div>

            </el-col>
            <el-col :span="4"></el-col>
          </el-row>
    <el-main>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>一键创建网站地图</h1>
          <div>
            <p>您的IP：{{userIP}} (<b style="color: red">本工具对网站违法内容进行跟踪记录)</b></p>
          </div>
          <div class="input-data" v-if="inputFlag">
            <div class="input-data-div">
              <el-input v-model="input" id="inputData" class="input-data-input" placeholder="请输入网站域名，加密网站请使用https://开头">
              </el-input>
            </div>
            <div class="input-data-submit">
              <el-button type="info" class="input-data-button" @click="createSideSse" :disabled="isDisabled">生成网站地图</el-button>
            </div>
          </div>
          <div class="show-data" v-if="showFlag">
            <div>你的网站地图已经生成({{ input }})</div>
            <div>发现网址：{{ urlCount }}</div>
            <div>索引网址：{{ createUrlCount }}</div>
            <div>生成日期：{{ createTime }}</div>
            <div>页面大小：{{pageSize}} 字节(Bytes)</div>
            <div>用时：{{ useTime }} 秒</div>
            <div style="color: red; font-weight: bolder">购买网站地图自动生成,自动按间隔时间生成,自动提交到百度搜索引擎</div>
            <div style="color: blue; font-weight: bolder">使用https/ssl证书,网站传输更安全,不被运营商劫持!</div>
            <div style="margin-top:20px; text-align: center" v-if="isDisabled">
              <p style="color: red;">正在生成中，请稍后...</p>
            </div>
            <div style="margin-top:20px; text-align: center" v-if="!isDisabled">
              <a :href="zipUrl" target="_blank">下载ZIP包</a> |
              <a :href="sitemapUrl" target="_blank">查看sitemap.xml</a> |
              <a :href="increase" target="_blank">查看本次增加网址</a> |
              <a :href="decrease" target="_blank">查看本次减少网址</a> |
              <a :href="side404" target="_blank">查看404网址</a> |
              <a :href="sideError" target="_blank">查看有错误网址</a> |
              <a :href="baiduSitemapUrl" target="_blank">查看百度专用sitemap</a> |
              <a :href="rssUrl" target="_blank">查看RSS.xml</a>
            </div>
            <div class="show-data-submit">
              <el-button type="info" class="show-data-button" @click="createSideSse" :disabled="isDisabled">重新生成网站地图</el-button>
            </div>
            <div class="show-data-submit">
              <el-button type="info" class="show-data-button" @click="setOtherSide" :disabled="isDisabled">换个网址</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>网站地图在线生成器介绍</h1>
          <div>
            <p>网站地图是一种格式化的XML文件（sitemap.xml）、HTML文件(sitemap.html)、TXT文件(sitemap.txt)或RSS文件(rss.xml)，这是重要的站长工具。 它用于把一个网站的所有（或大多数）网址集中在一个按一定的标准组织的地图文件中。以便于第三方（比如搜索引擎）、新闻订阅软件使用和更新。 目前的网站地图最流行的方式是使用sitemap.xml格式，它被主流搜索引擎（比如：百度、Google、Bing等）所使用。当网站有新网页时，把新的页面的URL地址更新到网站地图有利于搜索引擎的即时抓取。 因此网站地图应该是一个随时保持更新的用于表达一个站点全部网页的文件。</p>
            <img src="../assets/sitemap_gen_logo.jpg">
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>网站地图的使用方法</h1>
          <div>
            <p>网站地图文件使用最多的是向搜索引擎提交网站的网址列表。像百度在站长工具中，可以把自己的网站的sitemap.xml的网站地图URL提交上去，这样百度的蜘蛛就可以定期进行更新你的网站URL了。 当然网站地图是用于表示你网站的全部页面的URL列表，因此网站管理员需要定期更新网站地图中的URL列表，以便于搜索引擎可以更快发现网站的新页面。</p>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>Sitemap的重要性</h1>
          <div>
            <p>Sitemap是一个网站的全部URL列表，应该自动不定期更新这个列表，以使得使用sitemap的第三方搜索引擎、订阅软件可以即时发现你网站中的新的URL。 Sitemap是SEO中的首要任务，网站站长应向搜索引擎定期提交更新的URL列表，这就是网站地图（Sitemap），以让搜索引擎可以全面获得网站的网址信息和即时更新信息。 因此sitemap对于一个网站来说，它是十分重要的，同时定期更新网站地图，也是必不可少的环节。有些网站，内容增加了不少，但站点地图还是很老旧的，这样使用站点地图的软件就难以快速发现自己网站中的新增的网址。</p>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>在百度站长工具中如何提交sitemap网址</h1>
          <div>
            <p>在做SEO优化时，把网站地图地交到百度搜索引擎是一个重要的手段。在百度网站站长管理平台中首先验证你的网站，登录百度的站长工具，验证自己的域名后，即可在链接提交中提交你的网站地图网址（注意网站地图文件需要首先上传到你的网站根目录中）。如下图：</p>
            <img src="../assets/r_1690077396367_2731.jpg" />
            <p>第一步，手动或通过网站地图生成工具自动生成sitemap的XML文件(可通过本网站的地图生成器生成XML格式的网站地址)，本网站地图生成器将生成各个搜索引擎支持的全部格式的文件；</p>
            <p>第二步，将sitemap.xml等相关文件上传到网站目录下。比如网站为example.com，已制作了一个sitemap.xml的网站地图文件，将sitemap.xml上传至网站根目录即example.com/sitemap.xml；</p>
            <p>第三步，登录百度站长平台，确保提交sitemap数据的网站example.com已验证；</p>
            <p>第四步，点击链接提交，点sitemap提交，在“请填写数据文件地址”中输入网站地图的URL，本例即：example.com/sitemap.xml，输入验证码，点提交即可；</p>
            <p>通常系统后台会自动定期提取URL列表，但如果你想手动更新，只要选中要更新的sitemap文件，然后点“手动更新文件”即可。</p>
            <p>全自动推送(API推送或API提交)</p>
            <p>如果您已经注册了百度站长工具平台，您可以在在生成时添加高级选项中输入百度自动推送Token，这样系统在生成地图后将自动推送到百度的站长平台。</p>
            <p>使用主动推送功能会达到怎样效果</p>
            <p>及时发现：可以缩短百度爬虫发现您站点新链接的时间，使新发布的页面可以在第一时间被百度收录</p>
            <p>保护原创：对于网站的最新原创内容，使用主动推送功能可以快速通知到百度，使内容可以在转发之前被百度发现</p>
            <p>相关帮助可点击此查询(百度站长后台、API推送或API提交)</p>
            <p>如何获得百度Token和如何设置？</p>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>生成的地图文件意义</h1>
          <div>
            <p>站点地图生成器将生成多种不同的站点地图文件，主要包括：sitemap.xml、sitemap.txt、sitemap.html、rss.xml、increase.txt、decrease.txt、HTML树形站点地图、sitemap_404.txt等。下面分别介绍: </p>
            <p> sitemap.xml，这是大部分搜索引擎所使用的用于提交网站地图网址列表的XML文件格式；</p>
            <p> sitemap.txt，这是纯URL（网址）列表的站点地图，它是TXT格式，用于自动或手动通过HTTP工具向搜索引擎提交网址，在百度站长工具中可以使用该列表作为主动推送源；</p>
            <p> sitemap.html，这是可直接放在网站上用于用户访问或搜索引擎快速找到全站链接的页面（每页最多500条，自动分页）；</p>
            <p> rss.xml，这是RSS订阅软件所用，比如IE，可直接订阅；</p>
            <p> *increase.txt，本次生成的URL列表增加的网址，像百度搜索引擎就可以通过这个URL列表提交你网站的新连接，建议在百度站长工具中使用该列表作为主动推送源；</p>
            <p> *decrease.txt，本次生成的URL列表减少的网址;</p>
            <p> *sitetree.html，这是一种按发现顺序生成的你的网站树形HTML页面，具有一定的层次性;</p>
            <p> sitemap_images.xml ， 专用于Google的图片索引网站地图;</p>
            <p> split_*，5000条记录分页地图（部分搜索引擎）在处理超大XML时存在性能问题， 本系统把你的网站的网址以5000一页分页存放；</p>
            <p> sitemap_404.txt，网站地图生成程序发现的连接在网页中的站点内的404网址列表，该列表可供站长了解自己的哪些网址无法访问（404代码），便于处理和优化网站。</p>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4"></el-col>
        <el-col :span="16">
          <h1>友情链接</h1>
          <div class="flink" v-for="dict in friendLinks" :key="dict.dictType">
            <li><a target="_blank" :href="dict.dictValue" title="dict.dictLabel">{{dict.dictLabel}}</a></li>
          </div>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
    </el-main>
    <el-footer>
      <p class="footer-text">©1999-2024 创天下创业基地</p>
    </el-footer>
  </el-container>
  <el-dialog
      v-model="dialogVisible"
      title="提示"
      width="500"
  >
    <span>{{msg}}</span>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'; // 导入 Vue 3 的相关功能
import { ElLoading,ElMessage } from 'element-plus'
import { post,get } from '@/api/api';

    // const selectedOption = ref(''); // 创建响应式数据来存储选中的选项
    const activeIndex = ref("");
    const localIP = ref("");
    const input = ref("");
    const userIP = ref("");
    const inputFlag = ref(true);
    const showFlag = ref(false);
    const urlCount = ref(0);
    const createUrlCount = ref(0);
    const pageSize = ref(0);
    const useTime = ref(0);
    const createTime = ref("");
    const zipUrl = ref("");
    const sitemapUrl = ref("");
    const increase = ref("");
    const decrease = ref("");
    const side404 = ref("");
    const sideError = ref("");
    const baiduSitemapUrl = ref("");
    const rssUrl = ref("");
    const msg = ref("");
    const dialogVisible = ref(false);
    const isDisabled = ref(false);
    const eventSource = ref(null); // 用 ref 创建一个 Reactive 的 eventSource
    // const friendLinks = ref([
    //     {"dictLabel":"盲盒系统","dictValue":"https://www.hezi.show"}]
    // );
    const friendLinks = ref([
        {"dictLabel":"盲盒系统","dictValue":"https://www.hezi.show"},
        {"dictLabel":"四库书小说网","dictValue":"http://www.ghc-lxjd.com"}
    ]);


    // 定义 handleSelect 方法
    const handleSelect = (event) => {
      // selectedOption.value = event.target.value; // 更新选中的值
      console.log('选中的选项:', JSON.stringify(event)); // 输出选中的选项
    };

    const createSide = async () => {
      console.log("input" + input.value);
      if (input.value==null || input.value == '') {
        dialogVisible.value = true
        msg.value="请输入网址，例如：https://www.baidu.com"
        return
      }

      if (!/^https?:\/\//i.test(input.value) && !/^http?:\/\//i.test(input.value)) {
        input.value = 'http://' + input.value;
      }
      const loading = ElLoading.service({
        lock: true,
        text: '正在解析网站...',
        background: 'rgba(255, 255, 255, 0.7)',
      })

      const jsonData = {
        url: input.value
      };

      try {
        // 发送 POST 请求
        const response = await post('/sitemap/create', jsonData);
        console.log("response"+JSON.stringify(response))
        loading.close()
        if (response.code == 200) {
          const resultData = response.data;
          urlCount.value = resultData.urlCount;
          createUrlCount.value = resultData.createUrlCount;
          createTime.value = resultData.createTime;
          useTime.value = resultData.useTime;
          zipUrl.value = resultData.zipUrl;
          sitemapUrl.value = resultData.sitemapUrl;
          increase.value = resultData.increase;
          decrease.value = resultData.decrease;
          side404.value = resultData.side404;
          sideError.value = resultData.sideError;
          baiduSitemapUrl.value = resultData.baiduSitemapUrl;
          rssUrl.value = resultData.rssUrl;
          pageSize.value = resultData.pageSize;
        } else {
          dialogVisible.value = true
          msg.value="请求错误"
          return
        }
        // 处理响应
      } catch (error) {
        // 处理错误
        console.error('请求错误', error);
      }
      inputFlag.value = false;
      showFlag.value = true;

    };
    const createSideSse = async () => {
      isDisabled.value = true
      console.log("input" + input.value);
      if (input.value==null || input.value == '') {
        dialogVisible.value = true
        msg.value="请输入网址，例如：https://www.baidu.com"
        isDisabled.value = false
        return
      }

      if (!/^https?:\/\//i.test(input.value) && !/^http?:\/\//i.test(input.value)) {
        input.value = 'http://' + input.value;
      }

      const loading = ElLoading.service({
        lock: true,
        text: '正在解析网站...',
        background: 'rgba(255, 255, 255, 0.7)',
      })

      try {

        // 创建 EventSource 实例
        eventSource.value = new EventSource(process.env.VUE_APP_BASE_API+'/sitemap/createSse?url='+input.value);
        // 监听消息事件
        eventSource.value.onmessage = (event) => {
          loading.close()
          const result = JSON.parse(event.data);
          console.log(result);
          inputFlag.value = false;
          showFlag.value = true;
          if (result.code == 200) {
            const resultData = result.data;
            console.log("response"+JSON.stringify(resultData))
            urlCount.value = resultData.urlCount;
            createUrlCount.value = resultData.createUrlCount;
            createTime.value = resultData.createTime;
            useTime.value = resultData.useTime;
            zipUrl.value = resultData.zipUrl;
            sitemapUrl.value = resultData.sitemapUrl;
            increase.value = resultData.increase;
            decrease.value = resultData.decrease;
            side404.value = resultData.side404;
            sideError.value = resultData.sideError;
            baiduSitemapUrl.value = resultData.baiduSitemapUrl;
            rssUrl.value = resultData.rssUrl;
            pageSize.value = resultData.pageSize;
          } else {
            dialogVisible.value = true
            msg.value="请求错误"
            return
          }
        };

        // 错误处理
        eventSource.value.onerror = (event) => {
          console.log('SSE 状态:', eventSource.value.readyState);
          isDisabled.value = false
          if (eventSource.value.readyState == 2) {
            dialogVisible.value = true
            msg.value="请求错误"
            console.log('SSE 断开'+event);
            return
          } else if (eventSource.value.readyState == 0) {
            ElMessage({
                message: "生成网站地图成功.",
                type: 'success',
                plain: true,}
            )
          }
          eventSource.value.close();
        };
      } catch (error) {
        console.error('请求失败:', error);
        isDisabled.value = false
      }

    };

    // 使用 onMounted 钩子进行数据获取
    const fetchIP = async () => {
      console.log("test")
      try {
        const response = await fetch('http://ip-api.com/json/');
        const data = await response.json();
        userIP.value = data.query; // 设置 IP 地址
      } catch (error) {
        console.error('获取 IP 地址失败:', error); // 错误处理
      }
    };
    const getFriendLinks = async () => {
      console.log("friendLinks"+friendLinks.value);
      console.log("test")
      try {
        const response = await get('/system/dict/data/type/friend_inks');
        console.log("response"+JSON.stringify(response))
        // const data = await response.json();
        friendLinks.value = response.data;
      } catch (error) {
        console.error('获取 IP 地址失败:', error); // 错误处理
      }
    };
    const setOtherSide = async () => {
      input.value = ""
      inputFlag.value = true
      showFlag.value = false
      userIP.value = ""
      urlCount.value = 0
      createUrlCount.value = 0
      createTime.value = ""
      pageSize.value = 0
      useTime.value = 0
      zipUrl.value = ""
      sitemapUrl.value = ""
      increase.value = ""
      decrease.value = ""
      side404.value = ""
      sideError.value = ""
      baiduSitemapUrl.value = ""
      rssUrl.value = ""
    };
    // 使用 onMounted 代替 created
    onMounted(() => {
      // console.log("Component has been mounted");
      // fetchUsers(); // 组件挂载时获取用户数据
      fetchIP()
      getFriendLinks()
    });
</script>

<style  lang="scss" scoped>
.header{
  background-color: #42b983;

  .header-title{
    height: 100px;
    display: flex;
  }
}



/* 默认样式，适用于较大屏幕 */
.header-title-item {
  line-height: 100px;
  width: auto; /* 让宽度自适应内容 */
  font-size: 20px; /* 默认为大屏幕设置 */
  color: white; /* 大屏幕默认颜色 */
  text-align: center;
  cursor: pointer; /* 悬浮时显示小手 */
  margin: 0 20px; /* 默认左右间距 */
}

/* 手机屏幕 */
@media (max-width: 768px) {
  .header-title-item {
    line-height: 100px;
    width: 100%; /* 在小屏幕上宽度为100%以占满父容器 */
    font-size: 18px;
    color: white;
    text-align: center;
    margin: 5px 0; /* 适当的上下间距，去掉左右margin */
  }
}

/* 平板屏幕 */
@media (min-width: 769px) and (max-width: 1024px) {
  .header-title-item {
    line-height: 100px;
    width: 80px; /* 自适应宽度 */
    font-size: 18px;
    color: white;
    text-align: center;
    margin: 0 0px; /* 默认左右间距 */
  }
}

/* 大屏幕设备 */
@media (min-width: 1025px) and (max-width: 1600px){
  .header-title-item {
    line-height: 100px;
    width: auto; /* 自适应宽度 */
    font-size: 18px; /* 大屏幕字体稍大 */
    color: white; /* 在大屏幕上显示黑色 */
    margin: 0 10px; /* 较大的左右间距 */
  }
}

/* 大屏幕设备 */
@media (min-width: 1601px) {
  .header-title-item {
    line-height: 100px;
    width: auto; /* 自适应宽度 */
    font-size: 18px; /* 大屏幕字体稍大 */
    color: white; /* 在大屏幕上显示黑色 */
    margin: 0 50px; /* 较大的左右间距 */
  }
}

.header-title-item-active{
  background-color: rgba(0, 0, 0, 0.1); /* 半透明黑色背景 */
}



  .header-image {
    position:relative;
    width: 200px;
    height: 100%;
  }
  .header-image img {
    position:absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }

  .input-data{
    background: #e0e0e0;
    height: 500px;
  }

  .input-data-div{
    display: flex;              /* 设置为 Flexbox 布局 */
    justify-content: center;    /* 水平居中 */
  }

  .input-data-input{
    max-width: 500px;
    height: 50px;
    text-align: center;
    margin-top: 150px;
  }

  .input-data-submit{
    display: flex;              /* 设置为 Flexbox 布局 */
    justify-content: center;    /* 水平居中 */
  }

  .input-data-button{
    width: 500px;
    margin-top: 50px;
    height: 50px;
    font-size: 20px;
  }

  .footer-text {
    text-align: center;
  }

  .show-data{
    background: #ffffff;
    height: 500px;
  }

  .show-data div{
    padding: 4px 20px;
  }

  .show-data a{
    color: #08c;
  }
  .show-data a:hover {
    color: #0056b3; /* 悬停状态的背景颜色 */
    transform: scale(1.05); /* 悬停时略微放大 */
  }

.show-data-submit{
    display: flex;              /* 设置为 Flexbox 布局 */
    justify-content: center;    /* 水平居中 */
  }

  .show-data-button{
    width: 500px;
    margin-top: 20px;
    height: 50px;
    font-size: 20px;
  }

.flink {
  float: left;
  //background: #fff;
  border-radius: 12px;
  //width: 96%;
  //padding: 2%;
  b:before {
    content: "";
    float: left;
    background: #32ca99;
    width: 5px;
    height: 13px;
    margin-right: 5px;
    border-radius: 60px;
    //margin-top: 7px;
  }
  .clearfix::after {
    content: "";
    display: block;
    clear: both;
  }


  a {
    text-decoration: none;
  }

  a:link {
    color: blue;
  }
}

//.flink li:nth-child(1) {
//  float: none;
//  color: #212123;
//}
.flink li {
  line-height: 22px;
  color: #b7b7b7;
  margin: 5px;
  float: left;
}

ul, ol, li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}
</style>